import React from 'react';
import ComponentWrapper from '../../layout/ComponentWrapper';
import { StudyDesignOverview, StudyDesignArms, StudyCopyBlock } from '../index';

const HRTNBC = () => (
    
    <ComponentWrapper>
        <StudyDesignOverview title="Study Overview" description="1,174 patients with newly diagnosed, previously untreated high-risk early-stage TNBC were evaluated in a randomized, multicenter, double-blind, placebo-controlled trial" data={[
            [
                {
                    title: 'Patient Eligibility',
                    description: `
                        <p>Key Inclusion Criteria</p>
                        <ul>
                            <li>Patients with newly diagnosed, previously untreated high-risk early-stage TNBC</li>
                            <li>Patients were enrolled regardless of tumor PD&#8288;-&#8288;L1 expression</li>
                            <li>Tumor size >1 cm but ≤2 cm in diameter with nodal involvement or tumor size >2 cm in diameter regardless of nodal involvement</li>
                        </ul>
                        <p>Key Exclusion Criteria</p>
                        <ul>
                            <li>Patients with active autoimmune disease that required systemic therapy within 2 years of treatment</li>
                            <li>Patients with a medical condition that required immunosuppression</li>
                        </ul>
                    `,
                    type: 'box-gradient',
                    colspan: 2
                }
            ],
            [
                {
                    title: 'Randomized (2:1) N=1,174<sup>a,b</sup>',
                    colspan: 2
                }
            ],
            [
                {
                    type: 'arrow-gray',
                },
                {
                    type: 'arrow-gray',
                }
            ],
        ]} />
    
        <StudyDesignArms 
            title="Study Arms" 
            arms={[
                {
                    title: "KEYTRUDA (n=784)",
                    data: [
                        [
                            {
                                title: `
                                    <div class="h7">Neoadjuvant Regimen (cycles 1–4)</div>
                                `,
                                description: [
                                    {
                                        text: `KEYTRUDA 200&nbsp;mg Q3W on day 1`,
                                        textCenter: true
                                    },
                                    {
                                        text: `Carboplatin (AUC 5&nbsp;mg/mL/min Q3W on day 1 <b>OR</b> AUC 1.5&nbsp;mg/mL/min (weekly) on days 1, 8, and 15)`,
                                        conditional: 'and',
                                        textCenter: true
                                    },
                                    {
                                        text: `Paclitaxel (80&nbsp;mg/m<sup>2</sup> on days 1, 8, and 15)`,
                                        conditional: 'and',
                                        textCenter: true
                                    }
                                ],
                                type: 'box-multi-color-green'
                            }
                        ],
                        [
                            {
                                type: 'arrow-green',
                            }
                        ],
                        [
                            {
                                title: `
                                    <div class="h7">Neoadjuvant Regimen (cycles 5–8)</div>
                                `,
                                description: [
                                    {
                                        text: `KEYTRUDA 200&nbsp;mg Q3W on day 1`,
                                        textCenter: true
                                    },
                                    {
                                        text: `Doxorubicin (60&nbsp;mg/m<sup>2</sup> Q3W) on day 1 <b>OR</b> Epirubicin (90&nbsp;mg/m<sup>2</sup> Q3W) on day 1`,
                                        conditional: 'and',
                                        textCenter: true
                                    },
                                    {
                                        text: `Cyclophosphamide (600&nbsp;mg/m<sup>2</sup> Q3W) on day 1`,
                                        conditional: 'and',
                                        textCenter: true
                                    }
                                ],
                                type: 'box-multi-color-green'
                            }
                        ],
                        [
                            {
                                type: 'arrow-green',
                            }
                        ],
                        [
                            {
                                title: 'Surgery',
                            }
                        ],
                        [
                            {
                                type: 'arrow-green',
                            }
                        ],
                        [
                            {
                                title: `
                                    <div class="h7">Adjuvant Regimen (9 cycles)</div>
                                `,
                                description: [
                                    {
                                        text: `KEYTRUDA 200&nbsp;mg Q3W on day 1`,
                                        textCenter: true
                                    }
                                ],
                                type: 'box-multi-color-green'
                            }
                        ],
                    ]
                },
                {
                    title: "Placebo (n=390)",
                    data: [
                        [
                            {
                                title: `
                                    <div class="h7">Neoadjuvant Regimen (cycles 1–4)</div>
                                `,
                                description: [
                                    {
                                        text: `Placebo Q3W on day 1`,
                                        textCenter: true
                                    },
                                    {
                                        text: `Carboplatin (AUC 5&nbsp;mg/mL/min Q3W on day 1 <b>OR</b> AUC 1.5&nbsp;mg/mL/min (weekly) on days 1, 8, and 15)`,
                                        conditional: 'and',
                                        textCenter: true
                                    },
                                    {
                                        text: `Paclitaxel (80&nbsp;mg/m<sup>2</sup> on days 1, 8, and 15)`,
                                        conditional: 'and',
                                        textCenter: true
                                    }
                                ],
                                type: 'box-multi-color-gray'
                            }
                        ],
                        [
                            {
                                type: 'arrow-gray',
                            }
                        ],
                        [
                            {
                                title: `
                                    <div class="h7">Neoadjuvant Regimen (cycles 5–8)</div>
                                `,
                                description: [
                                    {
                                        text: `Placebo Q3W on day 1`,
                                        textCenter: true
                                    },
                                    {
                                        text: `Doxorubicin (60&nbsp;mg/m<sup>2</sup> Q3W) on day 1 <b>OR</b> Epirubicin (90&nbsp;mg/m<sup>2</sup> Q3W) on day 1`,
                                        conditional: 'and',
                                        textCenter: true
                                    },
                                    {
                                        text: `Cyclophosphamide (600&nbsp;mg/m<sup>2</sup> Q3W) on day 1`,
                                        conditional: 'and',
                                        textCenter: true
                                    }
                                ],
                                type: 'box-multi-color-gray'
                            }
                        ],
                        [
                            {
                                type: 'arrow-gray',
                            }
                        ],
                        [
                            {
                                title: 'Surgery',
                            }
                        ],
                        [
                            {
                                type: 'arrow-gray',
                            }
                        ],
                        [
                            {
                                title: `
                                    <div class="h7">Adjuvant Regimen (9 cycles)</div>
                                `,
                                description: [
                                    {
                                        text: `Placebo Q3W on day 1`,
                                        textCenter: true
                                    }
                                ],
                                type: 'box-multi-color-gray'
                            }
                        ],
                    ]
                }
        ]} 
        footnotes={[
            {
                label:'a.',
                text: `Randomization (R) was stratified by nodal status (positive vs negative), tumor size (T1/T2 vs T3/T4), and choice of carboplatin (Q3W vs weekly).`
            },
            {
                label:'b.',
                text: `All study medications were administered intravenously.`
            }
        ]}
        />

        <StudyCopyBlock 
            title="Main Efficacy Outcomes"
            description={`
                <p>Pathological complete response (pCR) rate</p>
                <ul>
                    <li>pCR was defined as absence of invasive cancer in the breast and lymph
                    nodes (ypT0/Tis ypN0) and was assessed by the blinded local pathologist
                    at the time of definitive surgery.</li>
                </ul>
                <p>Event-free survival (EFS)</p>
                <ul>
                    <li>EFS was defined as the time from randomization to the first occurrence
                    of any of the following events: progression of disease that precludes
                    definitive surgery, local or distant recurrence, second primary
                    malignancy, or death due to any cause.</li>
                </ul>

            `}
        />
         <StudyCopyBlock 
            title="Secondary Efficacy Outcome"
            description={`
                <p>Overall survival (OS)</p>
                <ul>
                    <li>OS was defined as the time from randomization to death from any cause.<sup>8</sup></li>
                </ul>

            `}
        />
        <StudyCopyBlock 
            title="Characteristics of Patients From KEYNOTE&#8288;-&#8288;522:"
            description={`
              <b>Baseline Characteristics (N=1,174)</b><br/>
                <ul>
                    <li>Median age, years (range): 49 (22–80)
                        <ul>
                            <li>Aged ≥65 years: 11%</li>
                        </ul>
                    </li>
                    <li>Female: 99.9%</li>
                    <li>Race
                        <ul>
                            <li>White: 64%</li>
                            <li>Asian: 20%</li>
                            <li>Black: 4.5%</li>
                            <li>American Indian or Alaska Native: 1.8%</li>
                        </ul>
                    </li>
                    <li>ECOG PS
                        <ul>
                            <li>0: 87% </li>
                            <li>1: 13%</li>
                        </ul>
                    </li>
                    <li>Menopausal status
                        <ul>
                            <li>Pre-menopausal: 56%</li>
                            <li>Post-menopausal: 44%</li>
                        </ul>
                    </li>
                    <li>Primary tumor classification
                        <ul>
                            <li>T1: 7%</li>
                            <li>T2: 68%</li>
                            <li>T3: 19%</li>
                            <li>T4: 7%</li>
                        </ul>
                    </li>
                    <li>Nodal involvement
                        <ul>
                            <li>N0: 49%</li>
                            <li>N1: 40%</li>
                            <li>N2: 11%</li>
                            <li>N3: 0.2%</li>
                        </ul>
                    </li>
                    <li>Overall disease stage
                        <ul>
                            <li>II: 75%</li>
                            <li>III: 25%</li>
                        </ul>
                    </li>
                <ul>
                
            `} 
            definitions={`
            AUC = area under the curve;
            ECOG PS = European Cooperative Group performance status;
            PD&#8288;-&#8288;1 = programmed death receptor-1;
            PD&#8288;-&#8288;L1 = programmed death ligand 1;
            Q3W = every 3 weeks.
            `} 
        />
 
    </ComponentWrapper>
);

export default HRTNBC;
